<template>
  <div class="con" v-if="!$store.state.pcshow">
      <img src="../../assets/img/index/yilianGl2.png" alt="" />
      <div class="btngroup clearfix">
        <!-- <router-link to="/zhongkeYC" :class="$route.path === '/zhongkeYC'? 'active': ''">
          <div class="companyIntro">
            <p>中科医创</p>
          </div>
        </router-link> -->
        <router-link to="/zhongxinGH"  :class="$route.path === '/zhongxinGH'? 'active': ''">
          <div class="structure">中鑫广汇</div>
        </router-link>
        <router-link to="/yilianGl"  :class="$route.path === '/yilianGl'? 'active': ''">
          <div class="structure">亿联管理</div>
        </router-link>
        <router-link to="/chanyeYQ"  :class="$route.path === '/chanyeYQ'? 'active': ''">
          <div class="structure">产业园区</div>
        </router-link>
      </div>
      <div class="hprofile">
        <h3 class="conh3">COMPANY PROFILE</h3>
        <h4 class="conh4">公司简介</h4>
        <img src="../../assets/img/index/yilianGl1.png" alt="">
        <!-- <h5>中关村高新技术企业</h5> -->
        <p>亿联体事业部拥有近100人的专业风控团队和自主研发的SaaS聚合平台，在医药行业的CSO+CRO全领域服 务上是国内领跑的服务商。致力于为药企规避相关业务经营中的法律风险与流程漏洞，确保其产品推广业务 合规开展，规避经营风险。</p>
        <p>在医药行业越来越严格的监管下，为药企的长足发展，亿联体事业部运用数字化营销、专业咨询等服务形式， 在垂直领域全链路采用复用打法，依托多样性服务结构的组合拳，帮助药企一站式解决业务经营合规化等问 题，进而达到平台化标准。</p>
      </div>
      <div class="yjGL">
        <h4><span></span>为什么选择我们</h4>
        <div class="advan clearfix">
          <div class="advan_li">
            <img src="../../assets/img/index/yilianGl3.png" alt="">
            <p>政府支持</p>
            <p>合作共赢</p>
          </div>
          <div class="advan_li">
            <img src="../../assets/img/index/yilianGl4.png" alt="">
            <p>国家政策</p>
            <p>精准解读</p>
          </div>
          <div class="advan_li">
            <img src="../../assets/img/index/yilianGl5.png" alt="">
            <p>合规方案</p>
            <p>量身定制</p>
          </div>
          <div class="advan_li">
            <img src="../../assets/img/index/yilianGl6.png" alt="">
            <p>量身定制</p>
            <p>管家服务</p>
          </div>
          <div class="advan_li">
            <img src="../../assets/img/index/yilianGl7.png" alt="">
            <p>专业财税</p>
            <p>咨询服务</p>
          </div>
        </div>
    </div>
    <div class="yj">
      <h4><span></span>业务组成</h4>
      <div class="support">
        <img src="../../assets/img/index/yilianGl8.png" alt="">
        <div class="supportcon clearfix">
          <div class="con_left">
            <h5>信息收集</h5>
            <ul>
              <li><span></span>目标产品的产品流向</li>
              <li><span></span>渠道与终端的产品进销存</li>
              <li><span></span>患者及用药</li>
              <li><span></span>竞品营销数据等信息的收集和处理</li>
            </ul>
          </div>
          <div class="con_left">
            <h5>终端维护</h5>
            <ul>
              <li><span></span>针对已经合作的医院等目标终端客户</li>
              <li><span></span>确保终端客户对目标产品的安全供应和使用</li>
              <li><span></span>进行学术推广、日常动态跟踪和维护</li>
            </ul>
          </div>
        </div>
        <img src="../../assets/img/index/yilianGl9.png" alt="">
        <div class="supportcon clearfix">
          <div class="con_left">
            <h5>渠道服务</h5>
            <ul>
              <li><span></span>针对目标区域内所需要的商业配送机构</li>
              <li><span></span>定期进行涉及药品流通与采购结算等</li>
              <li><span></span>相关客户服务支持</li>
            </ul>
          </div>
          <div class="con_left">
            <h5>医院产品准入服务</h5>
            <ul>
              <li><span></span>按目标区域医疗机构招标采购和产品</li>
              <li><span></span>进院工作需要的产品准入事务</li>
            </ul>
          </div>
        </div>
        <img src="../../assets/img/index/yilianGl10.png" alt="">
        <div class="supportcon clearfix">
          <div class="con_left">
            <h5>终端开发服务</h5>
            <ul>
              <li><span></span>针对基层医疗、零售药店、诊所、民营医疗等终端</li>
              <li><span></span>就产品实现准入、采购、上架而提供相应的服务支持</li>
            </ul>
          </div>
          <div class="con_left">
            <h5>渠道配送拓展服务</h5>
            <ul>
              <li><span></span>在目标区域内</li>
              <li><span></span>拓展符合配送能力及其相关 要求的商业</li>
              <li><span></span>并协助药企达成合作</li>
            </ul>
          </div>
        </div>
        <img src="../../assets/img/index/yilianGl11.png" alt="">
        <div class="supportcon clearfix">
          <div class="con_left">
            <h5>会务服务</h5>
            <ul>
              <li><span></span>为学术推广而开展的科室会、城市会、沙龙、专业论坛等会务的相关服务</li>
            </ul>
          </div>
          <div class="con_left">
            <h5>其他服务</h5>
            <ul>
              <li><span></span>市场调研、患者教育等</li>
              <li><span></span>其他一切在营销推广过程中产生的服 务内容</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="yj3">
      <div class="bus2">
        <img src="../../assets/img/index/yilianGl12.png" alt="">
        <h3>自主研发经营业务管理平台 </h3>
        <p>企业灵活用工和个人创业一体化解决方案</p>
        <p>聚焦企业灵活用工市场，企业发布灵活用工需求/任务，用户接取任务，执行并上传任务成果后可获得对应任务报酬</p>
        <div class="download clearfix">
          <img src="../../assets/img/index/yilianGl13.png" alt="">
          <img src="../../assets/img/index/yilianGl14.png" alt="">
        </div>
      </div>
    </div>
  </div>
  <!-- 亿联体管理/事业部 -->
  <div class="main" v-else>
    <img src="../../assets/img/index/imgYLSYB(1).png" alt="" />
    <div class="hatchTotalNav">
      <div class="hatchleft">
        <div class="hatchleftContent">
          <p>INCUBATION ENTERPRISE</p>
          <p>孵化企业</p>
        </div>
      </div>
      <div class="hatchNavRight">
        <div class="hatchNavRightContent">
          <!-- <router-link to="/zhongkeYC">
            <div>中科医创</div>
          </router-link> -->
          <router-link to="/zhongxinGH">
            <div>中鑫广汇</div>
          </router-link>
          <router-link
            to="/yilianGl"
            :class="
              $route.path === '/yilianGl' ? 'companyActive' : 'companyDefault'
            "
          >
            <div>
              <p>亿联体事业部</p>
              <div class="bluebottom"></div>
            </div>
          </router-link>
          <router-link to="/chanyeYQ">
            <div>
              <p>产业园区</p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="contentMain">
      <!-- 公司简介 -->
      <div class="companyIntro">
        <div class="IntroLeft">
          <h4>COMPANY PROFILE</h4>
          <h4>公司简介</h4>
          <p>
            亿联体事业部拥有近100人的专业风控团队和自主研发的SaaS聚合平台，在医药行业的CSO+CRO全领域服
            务上是国内领跑的服务商。致力于为药企规避相关业务经营中的法律风险与流程漏洞，确保其产品推广业务
            合规开展，规避经营风险。
          </p>
          <p>
            在医药行业越来越严格的监管下，为药企的长足发展，亿联体事业部运用数字化营销、专业咨询等服务形式，
            在垂直领域全链路采用复用打法，依托多样性服务结构的组合拳，帮助药企一站式解决业务经营合规化等问
            题，进而达到平台化标准。
          </p>
        </div>
        <div class="introRight">
          <img src="../../assets/img/index/imgYLSYB(2).png" alt="" />
        </div>
      </div>
      <!-- 业务内容标题 -->
      <div class="croBusiness">
        <p>为什么选择我们</p>
        <div class="blueBttom"></div>
        <div class="whySelect">
          <ul>
            <li>
              <img src="../../assets/img/index/imgYLSYB(3).png" alt="" />
              <h4>政府支持</h4>
              <h4>合作共赢</h4>
            </li>
            <li>
              <img src="../../assets/img/index/imgYLSYB(4).png" alt="" />
              <h4>国家政策</h4>
              <h4>精准解读</h4>
            </li>
            <li>
              <img src="../../assets/img/index/imgYLSYB(5).png" alt="" />
              <h4>合规方案</h4>
              <h4>量身定制</h4>
            </li>
            <li>
              <img src="../../assets/img/index/imgYLSYB(6).png" alt="" />
              <h4>专业团队</h4>
              <h4>管家服务</h4>
            </li>
            <li>
              <img src="../../assets/img/index/imgYLSYB(7).png" alt="" />
              <h4>专业财税</h4>
              <h4>咨询服务</h4>
            </li>
          </ul>
        </div>
        <div class="busyTitle">
          <p>业务组成</p>
          <div class="blueBttom"></div>
        </div>
      </div>
      <!-- 业务组成内容 -->
      <div class="busyContent">
        <ul>
          <li>
            <div class="busyContentTop">
              <img src="../../assets/img/index/imgYLSYB(8).png" alt="" />
            </div>
            <div class="busyContentBottom">
              <ul>
                <li>
                  <div class="bluePoint"></div>
                  <span>目标产品的产品流向</span>
                </li>
                <li>
                  <div class="bluePoint"></div>
                  <span>渠道与终端的产品进销存</span>
                </li>
                <li>
                  <div class="bluePoint"></div>
                  <span>患者及用药</span>
                </li>
                <li>
                  <div class="bluePoint"></div>
                  <span>竞品营销数据等信息的收集和整理</span>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div class="busyContentTop">
              <img src="../../assets/img/index/imgYLSYB(9).png" alt="" />
            </div>
            <div class="busyContentBottom">
              <ul>
                <li>
                  <div class="bluePoint"></div>
                  <span>针对已经合作的医院等目标终端客户</span>
                </li>
                <li>
                  <div class="bluePoint"></div>
                  <span>确保终端客户对目标产品的安全供应和使用</span>
                </li>
                <li>
                  <div class="bluePoint"></div>
                  <span>进行学术推广、日常动态跟踪和维护</span>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div class="busyContentTop">
              <img src="../../assets/img/index/imgYLSYB(10).png" alt="" />
            </div>
            <div class="busyContentBottom">
              <ul>
                <li>
                  <div class="bluePoint"></div>
                  <span>针对目标区域内所需要的商业配送机构</span>
                </li>
                <li>
                  <div class="bluePoint"></div>
                  <span>定期进行涉及药品流通与采购结算等</span>
                </li>
                <li>
                  <div class="bluePoint"></div>
                  <span>相关客户服务支持</span>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div class="busyContentTop">
              <img src="../../assets/img/index/imgYLSYB(11).png" alt="" />
            </div>
            <div class="busyContentBottom">
              <ul>
                <li>
                  <div class="bluePoint"></div>
                  <span>按目标区域医疗机构招标采购和产品</span>
                </li>
                <li>
                  <div class="bluePoint"></div>
                  <span>进院工作需要的产品准入事务</span>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div class="busyContentTop">
              <img src="../../assets/img/index/imgYLSYB(12).png" alt="" />
            </div>
            <div class="busyContentBottom">
              <ul>
                <li>
                  <div class="bluePoint"></div>
                  <span>针对基层医疗、零售药店、诊所、民营医疗等终端</span>
                </li>
                <li>
                  <div class="bluePoint"></div>
                  <span>就产品实现准入、采购、上架而提供相应的服务支持</span>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div class="busyContentTop">
              <img src="../../assets/img/index/imgYLSYB(13).png" alt="" />
            </div>
            <div class="busyContentBottom">
              <ul>
                <li>
                  <div class="bluePoint"></div>
                  <span>在目标区域内</span>
                </li>
                <li>
                  <div class="bluePoint"></div>
                  <span>拓展符合配送能力及其相关要求的商业</span>
                </li>
                <li>
                  <div class="bluePoint"></div>
                  <span>并协助药企达成合作</span>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div class="busyContentTop">
              <img src="../../assets/img/index/imgYLSYB(14).png" alt="" />
            </div>
            <div class="busyContentBottom">
              <ul>
                <li>
                  <div class="bluePoint"></div>
                  <span
                    >为学术推广而开展的科室会、城市会、沙
                    龙、专业论坛等会务的相关服务</span
                  >
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div class="busyContentTop">
              <img src="../../assets/img/index/imgYLSYB(15).png" alt="" />
            </div>
            <div class="busyContentBottom">
              <ul>
                <li>
                  <div class="bluePoint"></div>
                  <span>市场调研、患者教育等</span>
                </li>
                <li>
                  <div class="bluePoint"></div>
                  <span>其他一切在营销推广过程中产生的服务内容</span>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- app下载大图 -->
    <img src="../../assets/img/index/imgYLSYB(16).png" alt="" />
  </div>
</template>
<script>
export default {};
</script>

<style scoped lang="stylus">
  @media screen and (max-width: 960px) {
    .con{
      width: 100%;
      background: #F5F5F5;
      
      >img{
        display: block
        width:7.5rem;
        height:3rem;
      }

      .btngroup{
        width: 6.8rem;
        padding-left:0.35rem;
        padding-right:0.35rem; 
        a{
          display: block;
          width: 2rem;
          height: 0.68rem;
          background: #FFFFFF;
          float: left
          margin-top: 0.3rem;
          margin-right: 0.4rem;
          font-size: 0.26rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          line-height: 0.68rem;
          text-align: center; 
        }
        a.active{
          background: #0085D0;
          color: #ffffff;
        }
        a:nth-child(3){
          margin-right:0;
        }
      }
      .hprofile{
        display block
        width calc( 100% - 0.36rem )
        padding-left 0.36rem
        background #F5F5F5
        padding-bottom 0.2rem

        img{
          display: block
          width: 5rem
          height: 3rem
          margin-bottom: 0.32rem
        }
        
        h5{
          font-size: 0.24rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #0085D0;
          padding-top: 0.24rem;
          padding-bottom:0.2rem;
        }
        p{
          width: 6.86rem;
          font-size: 0.24rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          line-height: 0.45rem;
          margin-bottom:0.1rem;
          word-break:break-all;
        }
      }
      .yjGL{
        display: block;
        width: calc( 100% - 0.35rem );
        background: #ffffff;
        padding-left:0.35rem;
        padding-bottom:0.05rem;

        >h4{
          font-size: 0.26rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
          line-height: 0.39rem;
          padding-top:0.24rem;
          padding-bottom:0.32rem;
          span{
            display: inline-block;
            width: 0.03rem;
            height: 0.23rem;
            background: #0085D0;
            margin-right:0.07rem;
          }
        }
        .advan{
          padding-right: 0.34rem;
          .advan_li{
            width: 1.14rem;
            float: left;
            margin-right:1.65rem;
            margin-bottom:0.32rem;
            img{
              display: block;
              width:1.14rem;
              height:1.14rem;
            }
            p{
                padding-top: 0.27rem;
                font-size: 0.26rem;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #333333;
              }
          }
          .advan_li:nth-child(3){
            margin-right:0;
          }
        }
      }
      .yj{
        display: block;
        width: calc( 100% - 0.35rem );
        background: #ffffff;
        padding-left:0.35rem;
        padding-bottom:0.05rem;
        border-top: 0.4rem solid #f5f5f5;

        >h4{
          font-size: 0.26rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
          line-height: 0.39rem;
          padding-top:0.24rem;
          padding-bottom:0.32rem;
          span{
            display: inline-block;
            width: 0.03rem;
            height: 0.23rem;
            background: #0085D0;
            margin-right:0.07rem;
          }
        }
        .support{
          display: block;
          width:6.8rem;

          img{
            display: block;
            width: 100%;
            height:2rem;
          }
          .supportcon{
            display: block;
            width: 100%;
            .con_left{
              display: block;
              width:50%;
              float:left;
              h5{
                  font-size: 0.26rem;
                  font-family: Source Han Sans CN;
                  font-weight: 500;
                  color: #333333;
                  line-height: 0.3rem;
                  padding-top:0.24rem;
              }
              ul{
                padding-top:0.24rem;
                padding-bottom:0.15rem;
                li{
                  font-size: 0.24rem;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #333333;
                  line-height: 0.3rem;
                  display: flex;
                  align-items: center;
                  margin-bottom:0.15rem;
                  span{
                    display: inline-block;
                    width: 0.04rem;
                    height: 0.04rem;
                    background: #0085D0;
                    border-radius: 50%;
                    margin-right:0.12rem;
                  }
                }
              }
            }
          }
        }
      }
      .yj3{
        border-bottom:0.4rem solid #f5f5f5;
        display: block;
        width: 6.8rem;
        background: #ffffff;
        margin-left:0.35rem;
        margin-right:0.35rem;
        padding-bottom:0.05rem;
        border-radius: 0px 0px 0.1rem 0.1rem;
        margin-top: 0.32rem;

        .bus2{
          width:6.8rem;

          >img{
            display: block;
            width:6.8rem;
            height:3.5rem;
          }
          h3{
            font-size: 0.3rem;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            // line-height: 0.45rem;
            margin-top:0.32rem;
            margin-bottom:0.2rem;
            padding-left:0.3rem;
          }
          p{
            padding-left:0.3rem;
            margin-right:0.3rem;
            font-size: 0.24rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: 0.45rem;
            padding-bottom:0.2rem;
          }
          .download{
            padding-top:0.1rem;
            padding-bottom:0.4rem;
            img{
              display: block;
              width:1.91rem;
              height:1.85rem;
              float:left;
            }
            img:nth-child(1){
              padding-left: 0.19rem;
            }
            img:nth-child(2){
              padding-left: 0.71rem;
            }
          }
        }

      }
    }
    .conh3{
      padding-top: 0.4rem
      font-size: 0.24rem;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    }
    .conh4{
      font-size: 0.26rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      padding 0.15rem 0 0.24rem 0
    }
  }
  @media screen and (min-width: 960px) { 
    .main {
      font-family: Source Han Sans CN;
      color: #333;

      >img {
        margin-top: 100px;
        width: 100%;
      }

      .hatchTotalNav {
        position: relative;
        display: flex;
        margin: 0 auto;
        width: 1280px;

        .hatchleft {
          position: absolute;

          // top: -62px;
          // width: 645px;
          .hatchleftContent {
            width: 800px;
            height: 162px;
            background: linear-gradient(0deg, #0085D0, #09B7CF);
            position: absolute;
            top: -63px;
            left: -471px;

            p:nth-child(1) {
              height: 19px;
              padding: 53px 0 15px 456px;
              font-size: 24px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #FFFFFF;
              line-height: 39px;
            }

            p:nth-child(2) {
              width: 118px;
              height: 29px;
              margin-left: 456px;
              font-size: 29px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #FFFFFF;
              line-height: 39px;
            }
          }
        }

        .hatchNavRight {
          width: 1280px;
          margin: 0 auto;

          // 右边导航
          .hatchNavRightContent {
            flex: 1;
            color: #666666;
            box-sizing: border-box;
            height: 100px;
            background: #FFFFFF;
            border-right: 0px solid #8df;
            box-shadow: 0px 2px 6px 0px rgba(209, 209, 209, 0.65);
            display: flex;
            justify-content: space-around;
            align-items: center;
            position: relative;
            top: -1px;
            left: 329px;
            padding-right: 240px;

            // width: 1280px;

            // margin-right: auto;
            a:hover {
              div {
                color: #0085D0;
              }

              div:nth-child(2) {
              }
            }

            a:nth-child(3) {
              position: relative;

              .bluebottom {
                display: none;
                position: absolute;
                top: 60px;
                left: 0px;
                width: 100px;
                height: 2px;
                background: #0085D0;
              }
            }

            .companyActive {
              color: #0085D0;

              div:nth-child(2) {
                display: block;
              }
            }

            .companyDefault {
            }
          }
        }
      }

      .contentMain {
        .companyIntro {
          width: 1280px;
          margin: 0 auto;
          // padding: 119px 319px;
          padding: 119px 0;
          display: flex;

          .IntroLeft {
            padding-right: 24px;

            h4 {
              font-size: 24px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #333333;
            }

            h4:nth-child(2) {
              margin: 24px 0 60px 0;
              font-size: 26px;
              font-weight: 400;
            }

            p {
              height: 76px;
              font-size: 16px;
              font-weight: 400;
              line-height: 30px;
              color: #333333;
            }

            p:nth-child(3) {
              margin-bottom: 40px;
            }

            p:nth-child(4) {
              margin-bottom: 40px;
            }
          }

          .introRight {
            >img {
              width: 498px;
              height: 514px;
            }
          }
        }

        .croBusiness {
          width: 1280px;
          margin: 0 auto;

          // padding: 0 319px;
          p {
            text-align: center;
            font-size: 30px;
            font-weight: 500;
            color: #333333;
            line-height: 39px;
            margin-bottom: 41px;
          }

          .blueBttom {
            text-align: center;
            margin: 41px auto;
            width: 42px;
            border-bottom: 5px solid #0085D0;
          }

          .whySelect {
            ul {
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;

              li {
                img {
                  wdith: 203px;
                  height: 203px;
                  margin: 100px 0 60px 0;
                }

                h4 {
                  text-align: center;
                  height: 25px;
                  font-size: 26px;
                  font-weight: 500;
                  line-height: 39px;

                  &:nth-child(3) {
                    margin: 20px 0 40px 0;
                  }
                }
              }
            }
          }

          .busyTitle {
            margin: 80px 0 65px;
            text-align: center;
          }
        }

        .busyContent {
          // padding: 0 319px;
          background-color: #F8F9FA;

          >ul {
            width: 1280px;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 77px 0 91px 0;

            >li {
              box-sizing: border-box;
              margin-bottom: 88px;
              background-color: #fff;

              &:nth-child(2) {
                .busyContentBottom {
                  ul>li:nth-child(2) {
                    margin-bottom: 20px;
                  }
                }
              }

              &:nth-child(5) {
                .busyContentBottom {
                  ul>li:nth-child(1) {
                    margin-bottom: 20px;
                  }
                }
              }

              .busyContentBottom {
                heigh: 150px;
                padding: 24px 0 0 45px;

                ul {
                  >li {
                    position: relative;
                    // margin-bottom: 10px;
                    width: 251px;
                    height: 39px;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 25px;
                    display: flex;
                    align-items: flex-start;
                    justify-content: left;

                    .bluePoint {
                      position: absolute;
                      top: 10px;
                      left: -14px;
                      width: 6px;
                      height: 6px;
                      margin-right: 4px;
                      background: #2F9FF1;
                      border-radius: 50%;
                    }
                  }
                }
              }
            }
          }
        }

        img {
          width: 100%;
        }
      }
    }
  }
</style>
